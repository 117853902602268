import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Helmet from 'react-helmet'
import {MdKeyboardArrowDown} from 'react-icons/md'
import Markdown from 'react-markdown'

import config from './config'
import {scroll, isMobile, formatAmount, formatWeight, isTouchDevice, sortByPosition} from './utils'
import gmaps from './gmaps'

import './Home.css'

import Header from './Header'
import ContactForm from './Contact'
import {matchPartner, PARTNER} from './actions'

const VIDEO_ID = 'W9JxQy-N4_w'
const VIDEO_RATIO = 1080 / 1920

const SLIDES = [1, 2, 3, 4, 5]
const WEEK_DAYS = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
const MONTHS = ['JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUIN', 'JUIL', 'AOUT', 'SEPT', 'OCT', 'NOV', 'DEC']
const PLACE_TYPES = {
  sale: 'Point de vente',
  deposit: 'Point de retrait',
  both: 'Point de vente et de retrait',
  restaurant: 'Restaurant',
}

/*global google:false*/
/*global YT:false*/

window.onYouTubeIframeAPIReady = () => {
  const container = document.getElementById('player')
  const rect = container.getBoundingClientRect()
  const ratio = rect.height / rect.width
  if (ratio < VIDEO_RATIO) {
    // wide
    container.style.width = '100%'
    container.style.height = `${VIDEO_RATIO * 100}vw`
    container.style.top = `${-((rect.width * VIDEO_RATIO) - rect.height) / 2}px`
  } else {
    // narrow
    container.style.width = `${100 / VIDEO_RATIO}vh`
    container.style.height = '100%'
    container.style.left = `${-((rect.height / VIDEO_RATIO) - rect.width) / 2}px`
  }

  new YT.Player(container, {
    videoId: VIDEO_ID,
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: 1,
      mute: 1,
      loop: 1,
      controls: 0,
      disablekb: 1,
      fs: 0,
      iv_load_policy: 3,
      modestbranding: 1,
      showinfo: 0,
      playlist: VIDEO_ID,
    },
  })
}

const googleMapsLink = (address) => {
  if (!address) {
    return ''
  }
  return `<a href="https://www.google.fr/maps/search/?api=1&query=${encodeURIComponent(address.replace('\n', ' '))}">${address.replace('\n', '<br />')}</a>`
}

const productMapper = ({id, photo, title, description, price, weight, type}) => (
  <li key={id} style={{backgroundImage: `url(${photo})`}}>
    <div className="caption">
      <h3>{title.replace(/[êëè]/g, 'e')}</h3>
      <hr />
      <p className="description">{description}</p>
      <p>{formatAmount(price)} / {formatWeight(weight, type)}</p>
    </div>
  </li>
)

class Home extends Component {
  state = {
    slide: 1,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    gmaps(this.handleMap)
    if (isTouchDevice()) {
      return
    }

    if (window.YT) {
      window.onYouTubeIframeAPIReady()
    } else {
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      document.head.appendChild(tag)
    }
  }

  componentWillReceiveProps(props) {
    if (props.places && !this.props.places && this.map) {
      this.placeMarkers(props.places)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  WINDOW_HEIGHT = window.innerHeight // to prevent from changing in render
  autoplay = true

  handleMap = () => {
    this.map = new google.maps.Map(this.refs.map, {
      zoom: 13,
      center: {lat: 43.6, lng: 1.45}, // Toulouse
    })
    if (this.props.places) {
      this.placeMarkers(this.props.places)
    }
  }

  placeMarkers = (places) => {
    this.markers = []
    this.infos = []
    places.forEach((place) => {
      if (!place.lat || !place.lon) {
        return
      }
      let type
      if (place.types.sale && place.types.deposit) {
        type = 'both'
      } else {
        if (place.types.sale) {
          type = 'sale'
        }
        if (place.types.deposit) {
          type = 'deposit'
        }
        if (place.types.restaurant) {
          type = 'restaurant'
        }
      }
      const marker = new google.maps.Marker({
        position: {lat: place.lat, lng: place.lon},
        map: this.map,
        title: place.title,
        icon: {
          url: `/images/marker-${type}.png`,
          size: new google.maps.Size(27, 54.5),
          scaledSize: new google.maps.Size(27, 54.5),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13.5, 54.5),
        },
      })
      const info = new google.maps.InfoWindow({
        content: `<h3>${place.title}</h3>
          <p>${PLACE_TYPES[type]}</p>
          <p>${googleMapsLink(place.address)}</p>`,
      })
      marker.addListener('click', () => {
        this.infos.forEach((other) => {
          other.close()
        })
        info.open(this.map, marker)
      })
      this.markers.push(marker)
      this.infos.push(info)
    })
  }

  handleScroll(target) {
    scroll('#' + target, isMobile() ? 0 : -100)
  }

  onScroll = () => {
    if (!this.autoplay) {
      return
    }

    const {top, bottom} = this.refs.gallery.getBoundingClientRect()
    const isVisible = (top > 0) && (bottom < (window.innerHeight + 50))

    if (isVisible && !this.interval) {
      this.interval = setInterval(this.nextSlide, 2000)
    } else if (this.interval && !isVisible) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  nextSlide = () => {
    const {slide} = this.state
    this.setState({
      slide: ((slide === SLIDES.length) ? 1 : (slide + 1)),
    })
  }

  handleGallery(slide, event) {
    event.preventDefault()
    this.setState({
      slide,
    })
    if (this.autoplay) {
      clearInterval(this.interval)
      this.autoplay = false
    }
  }

  render() {
    const {products, events, places, settings} = this.props
    const {slide} = this.state

    let schemaTag
    if (products.length) {
      const ldJson = {
        '@context': 'http://schema.org',
        '@type': 'ItemList',
        itemListElement: products.map(({id, title, photo, description, price}, index) => ({
          '@type': 'ListItem',
          position: index + 1,
          item: {
            '@type': 'Product',
            name: title,
            brand: {
              '@type': 'Thing',
              name: 'La Brewlangerie',
            },
            image: photo,
            description,
            offers: {
              '@type': 'Offer',
              priceCurrency: 'EUR',
              price,
              availability: 'http://schema.org/InStock',
              seller: {
                '@type': 'Organization',
                name: 'La Brewlangerie',
              },
            },
            url: config.app.url + '#product/' + id,
          },
        })),
      }

      schemaTag = (
        <script type="application/ld+json">
          {JSON.stringify(ldJson)}
        </script>
      )
    }

    const breads = products.filter(({type}) => type === 'bread').sort(sortByPosition)
    const permanentBeers = products.filter(({type, availability}) => type === 'beer' && availability === 'permanent').sort(sortByPosition)
    const temporaryBeers = products.filter(({type, availability}) => type === 'beer' && availability === 'temporary').sort(sortByPosition)
    const packs = products.filter(({type}) => type === 'pack').sort(sortByPosition)
    const others = products.filter(({type}) => type === 'other').sort(sortByPosition)

    const splashStyle = {}
    if (isTouchDevice()) {
      splashStyle.height = this.WINDOW_HEIGHT
      splashStyle.backgroundImage = 'url("/images/splash.jpg")'
    }

    const actionsClassname = events.length > 3 ? 'action large' : 'action'

    return (
      <div id="home-container">
        <Helmet>
          <meta name="robots" content={PARTNER === '_public' ? 'index, follow' : 'noindex'} />
          {schemaTag}
        </Helmet>
        <Header />
        <main>
          <section id="splash" style={splashStyle}>
            <div id="video">
              <div id="player" />
            </div>
            <div id="content">
              <h1>Pain d’antan<br /><span>&amp; bières artisanales</span></h1>
              <div id="location">Toulouse</div>
              <button onClick={this.handleScroll.bind(this, 'concept')}>
                <MdKeyboardArrowDown size={100} color="white" />
              </button>
            </div>
          </section>
          <section id="concept">
            <h2>{settings.title}</h2>
            <div id="concept-content">
              <div id="concept-text">
                <h3>{settings.subtitle}</h3>
                <Markdown source={settings.text} />
                <img src="/images/bio.png" alt="FR-BIO-12" />
              </div>
              <div id="gallery-container">
                <div id="gallery" ref="gallery">
                  <div id="gallery-content" style={{transform: `translateX(-${(slide - 1) * (isMobile() ? window.innerWidth : 333)}px)`}}>
                    {SLIDES.map((id) => <img key={id} src={`/images/gallery-${id}.jpg`} alt="" />)}
                  </div>
                </div>
                <div id="gallery-controller">
                  {SLIDES.map((id) => <button key={id} className={id === slide ? 'active' : ''} onClick={this.handleGallery.bind(this, id)}>&nbsp;</button>)}
                </div>
              </div>
            </div>
          </section>
          <section id="products">
            {breads.length > 0 && (
              <>
                <h2>Nos pains Bio</h2>
                <ul className="product-gallery">
                  {breads.map(productMapper)}
                </ul>
              </>
            )}
            {permanentBeers.length > 0 && (
              <>
                <h2>Nos bières Bio</h2>
                <ul className="product-gallery">
                  {permanentBeers.map(productMapper)}
                </ul>
              </>
            )}
            {temporaryBeers.length > 0 && (
              <>
                <h2>Nos bières éphémères <span>(non bio)</span></h2>
                <ul className="product-gallery">
                  {temporaryBeers.map(productMapper)}
                </ul>
              </>
            )}
            {packs.length > 0 && (
              <>
                <h2>Nos packs</h2>
                <ul className="product-gallery">
                  {packs.map(productMapper)}
                </ul>
              </>
            )}
            {others.length > 0 && (
              <>
                <h2>Nos accessoires</h2>
                <ul className="product-gallery">
                  {others.map(productMapper)}
                </ul>
              </>
            )}
          </section>
          <section id="order">
            <h2>Pour passer votre commande<br /><span>Deux solutions</span></h2>
            <div className="row">
              <div className={actionsClassname}>
                <Link to="/subscribe" className="subscribe">s’abonner</Link>
              </div>
              <div>
                <h3>Abonnez-vous à La Brewlangerie !</h3>
                <p>Choisissez vos pains et venez les récupérer chaque semaine dans le point de retrait de votre choix.</p>
              </div>
            </div>
            <div className="row">
              <div className={actionsClassname + ' dates'}>
                {events.map(({id, deadlineTime}) => {
                  const date = new Date(id)
                  const day = WEEK_DAYS[date.getDay()].substr(0, 3)
                  const month = MONTHS[date.getMonth()]
                  const deadline = new Date(deadlineTime)
                  const deadlineDay = WEEK_DAYS[deadline.getDay()]
                  const deadlineHour = deadline.getHours()
                  return (
                    <div key={id} className="date">
                      <Link to={`/order/${id}`}>{day}<br /><span>{date.getDate()}</span><br />{month}</Link>
                      <p className="deadline">Commandez avant le <strong>{deadlineDay} à {deadlineHour}h</strong></p>
                    </div>
                  )
                })}
              </div>
              <div>
                <h3>Commande ponctuelle</h3>
                <p>Choisissez la prochaine fournée à laquelle vous souhaitez participer et personnalisez votre commande.</p>
              </div>
            </div>
          </section>
          <section id="places">
            <h2>Pour se procurer nos produits<br /><span>Deux solutions</span></h2>
            <div className="columns">
              <div>
                <div className="row">
                  <div>
                    <img src="/images/deposit.png" alt="Point de retrait" />
                  </div>
                  <h3><strong>Choisissez votre pain</strong>, payez directement en ligne et venez le récupérer dans nos points de retrait</h3>
                </div>
                <ul>
                  {places && places.filter(({types}) => types.deposit).sort(sortByPosition).map(({id, title, description}) => <li key={id}><strong>{title}</strong><br />{description}</li>)}
                </ul>
                <br />
                <div className="row">
                  <div>
                    <img src="/images/restaurant.png" alt="Restaurant" />
                  </div>
                  <h3><strong>Dégustez notre pain</strong> dans un des restaurants partenaires</h3>
                </div>
                <ul>
                  {places && places.filter(({types}) => types.restaurant).sort(sortByPosition).map(({id, title, description}) => <li key={id}><strong>{title}</strong><br />{description}</li>)}
                </ul>
              </div>
              <div>
                <div className="row">
                  <div>
                    <img src="/images/sale.png" alt="Point de vente" />
                  </div>
                  <h3><strong>Rendez-vous</strong> dans l’un de nos points de vente, et choississez votre pain sur place</h3>
                </div>
                <ul>
                  {places && places.filter(({types}) => types.sale).sort(sortByPosition).map(({id, title, description}) => <li key={id}><strong>{title}</strong><br />{description}</li>)}
                </ul>
              </div>
            </div>
          </section>
          <div ref="map" id="gmap" style={isMobile() ? {height: this.WINDOW_HEIGHT} : null} />
        </main>
        <section id="contact">
          <h2>Contacter La Brewlangerie</h2>
          <ContactForm />
        </section>
      </div>
    )
  }
}

const mapStateToProps = ({db}) => {
  const places = db.places && db.places.filter(({types, partners}) => matchPartner(partners) || !types.deposit)

  const events = (places && db.events) && db.events.filter((event) => {
    const eventPlaces = places.filter(({id}) => event.bread_places[id] || event.beer_places[id] || event.other_places[id])
    return (eventPlaces.length > 0 && event.deadlineTime > (Date.now() + 300000 /* 5 minutes ahead */))
  }).slice(0, 4)

  return {
    products: db.products ? db.products.filter(({availability}) => (availability !== 'disabled')) : [],
    events: events || [],
    places,
    settings: (db.settings && (db.settings[PARTNER] || db.settings._public)) || {},
  }
}

export default connect(mapStateToProps)(Home)
